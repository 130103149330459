html, body {
    margin: 0;
    font-family: -apple-system, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
    width: 0.75em;
}

::-webkit-scrollbar-thumb {
    background: #FF0000;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.1);
    outline: 1px solid slategrey;
}

#right-bottom-elements {
    scroll-behavior: smooth;
}
